import "@yumgmbh/gatsby-theme-yum-components/src/scss/style.scss"

export const onRouteUpdate = ({ location }) => {
  /* Since hassia-consent is not spa-able*/
  var spaconsent = document.head.querySelector("#hassia-consent-spa")
  if (spaconsent) {
    spaconsent.parentNode.removeChild(spaconsent)
  }

  var s = document.createElement("script")
  s.src = "/hassia-consent_rapps.de_20230922.js"
  s.id = "hassia-consent-spa"
  s.dataset.dialogDelay = 0
  s.dataset.rouned = true
  s.dataset.mainColor = "#004f99"
  s.dataset.accentColor = "#ffda1a"
  s.defer = true

  document.head.append(s)
  return null
}
